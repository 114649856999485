module.exports={
    "$": "$",
    "head_t1": "Официальный сайт правительства Украины",
    "gengduo_yd": "читать далее",
    "fenxiang_wz": "поделиться сайтом",
    "zong_sj": "Общая сумма сбора",
    "xiangmu": "проект",
    "dashi": "посол",
    "liji_jz": "Пожертвовать сейчас",
    "shipin_cw": "Это видео невозможно воспроизвести. Повторите попытку позже.",
    "banner_t1": "Инициатива Президента Украины",
    "banner_t2": "Официальная украинская платформа по сбору средств",
    "guank_sp": "смотреть видео",
    "zhichi_wm": "наши сторонники",
    "zuixin_xm": "Последние проекты",
    "juanz_lx": {
        "0": "защита",
        "1": "Образование и наука",
        "2": "гуманитарный",
        "3": "медицинская помощь"
    },
    "suoyou_xm": "Показать все элементы",
    "report_t1": "Прозрачность и эффективность",
    "report_t2": "ZEROAGENCY предоставляет еженедельные отчеты о распределении средств по пяти направлениям",
    "report_t3": "Люди со всего мира объединились и готовы помочь Украине. ZEROAGENCY стремится увеличить пожертвования в Украину и обеспечить эффективность и прозрачность их распределения.",
    "guanyu_wz": "О Юнайтед24",
    "shouji_zs": "Общая коллекция",
    "juanz_zj": "Пожертвовать напрямую",
    "baogao_qw": "Полный текст отчета",
    "dashi_wm": "наш посол",
    "suoyou_dsxs": "Показать всех послов",
    "fabu_sj": "Публикация в социальных сетях",
    "fenx": "делиться",
    "dingy_t1": "Подпишитесь на еженедельную рассылку",
    "dingy_t2": "Подпишитесь на отчеты, новости и обновления ZEROAGENCY",
    "fenx_p": {
        "0": "Фейсбук",
        "1": "нефтяная труба",
        "2": "телеграфировать"
    },
    "dingye": "подписка",
    "shuru_yx": "Введите адрес электронной почты",
    "zuixin_xx": "последние новости",
    "suoyou_xwxs": "Показать все новости",
    "zhichi_t1": "Поддержите Украину в это критическое время",
    "juank_lj": "Пожертвовать сейчас",
    "nian": "Год",
    "yinsi_sm": "Заявление о конфиденциальности",
    "lianxi_wm": "связаться с нами",
    "lianxi_t1": "Если у вас есть какие-либо другие вопросы или проблемы",
    "xinwen": "Новости",
    "juanzhu_z": "Доноры",
    "guanyu_wm": "о нас",
    "yiban_wt": "Общие вопросы",
    "duiyu_mt": "для СМИ",
    "duiyu_hz": "Партнер",
    "sy_xm": "Все детали",
    "xiangm_yw_xs": "Показать реализованные проекты",
    "project_t1": "Мы ищем партнеров в компаниях-производителях, которые готовы помочь нам удовлетворить наши конкретные потребности.",
    "project_t2": "Чтобы стать партнером, напишите",
    "project_t3": "партнерство@u24.gov.ua",
    "project_t4": "Мы ищем партнеров в компаниях-производителях, которые готовы помочь нам удовлетворить наши конкретные потребности.",
    "project_t5": "ZEROAGENCY стремится увеличить пожертвования в Украину и обеспечить эффективность и прозрачность их распределения. Официальная платформа защищает вас от задержек, посредников и мошенничества.",
    "envoy_t1": "Президент Зеленский сплотил лидеров общественного мнения со всего мира, чтобы помочь Украине.",
    "envoy_t2": "Актеры и режиссеры, музыканты, ученые и спортсмены объединяются, чтобы сформировать команду послов ZEROAGENCY. В этот поворотный момент истории их объединило желание помочь Украине. Они помогают платформе собирать средства для Украины и привлечь к ней внимание международного сообщества, служа постоянным напоминанием о ее борьбе за свободу и ценности демократического мира. Их вклад бесценен.",
    "suoyou_ds": "все послы",
    "jiazai_gd": "Загрузи больше",
    "record_input": "Поиск по имени или ключевому слову",
    "suoyou_gj": "Все страны /регионы",
    "juanzheng_zgs": "Истории доноров",
    "ditu": "карта",
    "ka": "Карта",
    "suoyou_xw": "Все новости",
    "meiti_bd": "сообщения СМИ",
    "ruhe_gz": "как он работает",
    "zijin_zz": "Финансирование",
    "xianshi_qb": "показать все",
    "fangx": "направление",
    "xuanze_nyj": "Выберите, куда вы хотите сделать пожертвование",
    "jijin_zz": "Финансирование",
    "xuanze": "выбирать",
    "dfk": "способ оплаты",
    "juank_srje": "Введите сумму пожертвования",
    "mingcheng": "имя",
    "yinhang_dh": "Номер телефона банка",
    "yinh_m": "название банка",
    "yinhang_zh": "Банковский счет",
    "yinhang_yh": "пользователь банка",
    "quer_fk": "подтвердить оплату",
    "shuru_jkr": "Введите имя донора",
    "jiet_sc": "Загрузите скриншот пожертвования",
    "shif_fn": "высвободить гнев",
    juan_k:'Пожертвуйте',
    juan_zjl:'Запис пожертвування',
    queding:'звичайно',
    zheng_s:'Залиште свою електронну адресу та контактну адресу, щоб ми могли надіслати вам сертифікат любові та кубок',
    'Please enter your email address':"Будь ласка, введіть свою електронну адресу",
    "You have subscribed to the latest news":"Ви підписані на останні новини"


}