module.exports = {
    "$": "$",
    "head_t1": "Official website of the Ukrainian government",
    "gengduo_yd": "read more",
    "fenxiang_wz": "share website",
    "zong_sj": "Total collection amount",
    "xiangmu": "project",
    "dashi": "ambassador",
    "liji_jz": "Donate now",
    "shipin_cw": "This video cannot be played, please try again later",
    "banner_t1": "Initiative of the President of Ukraine",
    "banner_t2": "Ukraine’s official fundraising platform",
    "guank_sp": "watch video",
    "zhichi_wm": "our supporters",
    "zuixin_xm": "Latest projects",
    "juanz_lx": {
        "0": "defense",
        "1": "Education and Science",
        "2": "humanitarian",
        "3": "medical assistance"
    },
    "suoyou_xm": "Show all items",
    "report_t1": "Transparency and efficiency",
    "report_t2": "ZEROAGENCY provides weekly fund allocation reports in five directions",
    "report_t3": "People from all over the world are united in their willingness to help Ukraine. ZEROAGENCY aims to increase donations to Ukraine and ensure efficiency and transparency in their distribution.",
    "guanyu_wz": "About ZEROAGENCY",
    "shouji_zs": "Total collection",
    "juanz_zj": "Direct donation",
    "baogao_qw": "Full report",
    "dashi_wm": "Our Ambassadors",
    "suoyou_dsxs": "Show all ambassadors",
    "fabu_sj": "Post on social media",
    "fenx": "share",
    "dingy_t1": "Subscribe to our weekly newsletter",
    "dingy_t2": "Subscribe to ZEROAGENCY reports, news and updates",
    "fenx_p": {
        "0": "Facebook",
        "1": "YouTube",
        "2": "telegraph"
    },
    "dingye": "subscription",
    "shuru_yx": "Enter your email address",
    "zuixin_xx": "latest news",
    "suoyou_xwxs": "View all news",
    "zhichi_t1": "Supporting Ukraine at this critical moment",
    "juank_lj": "Donate now",
    "nian": "Year",
    "yinsi_sm": "Privacy Statement",
    "lianxi_wm": "contact us",
    "lianxi_t1": "If you have any other questions or concerns",
    "xinwen": "news",
    "juanzhu_z": "Donors",
    "guanyu_wm": "about Us",
    "yiban_wt": "General questions",
    "duiyu_mt": "for media",
    "duiyu_hz": "Partner",
    "sy_xm": "All items",
    "xiangm_yw_xs": "Show completed projects",
    "project_t1": "We are looking for partners in manufacturing companies who are willing to help us meet our specific needs",
    "project_t2": "To become a partner write to",
    "project_t3": "partnership@u24.gov.ua",
    "project_t4": "We are looking for partners in manufacturing companies who are willing to help us meet our specific needs.",
    "project_t5": "ZEROAGENCY aims to increase donations to Ukraine and ensure efficiency and transparency in their distribution. The official platform protects you from delays, intermediaries and fraud.",
    "envoy_t1": "President Zelensky has rallied opinion leaders from around the world to help Ukraine.",
    "envoy_t2": "Actors and directors, musicians, scientists and athletes come together to form the ZEROAGENCY ambassador team. At this turning point in history, the will to help Ukraine brought them together. They help the platform raise funds for Ukraine and put it in the international spotlight, serving as a constant reminder of its fight for freedom and the values of a democratic world. Their input is priceless.",
    "suoyou_ds": "all ambassadors",
    "jiazai_gd": "load more",
    "record_input": "Search by name or keyword",
    "suoyou_gj": "All countries /regions",
    "juanzheng_zgs": "Donor Stories",
    "ditu": "map",
    "ka": "Card",
    "suoyou_xw": "All news",
    "meiti_bd": "media reports",
    "ruhe_gz": "how he works",
    "zijin_zz": "Funding",
    "xianshi_qb": "display all",
    "fangx": "direction",
    "xuanze_nyj": "Choose where you want to donate",
    "jijin_zz": "Funding",
    "xuanze": "choose",
    "dfk": "payment method",
    "juank_srje": "Enter donation amount",
    "mingcheng": "name",
    "yinhang_dh": "Bank phone number",
    "yinh_m": "Bank name",
    "yinhang_zh": "Bank Account",
    "yinhang_yh": "bank user",
    "quer_fk": "confirm payment",
    "shuru_jkr": "Enter donor name",
    "jiet_sc": "Upload screenshot of donation",
    "shif_fn": "release anger",
    juan_k:'Donate',
    juan_zjl:'Donation record',
    queding:'Sure',
    zheng_s:'Leave your email and contact address so we can send you a love certificate and trophy',
    'Please enter your email address':"Please enter your email address",
    "You have subscribed to the latest news":"You have subscribed to the latest news"

}