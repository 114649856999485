<template>
  <router-view />
  <div v-if="loading" class="app-loading m-flex m-f-center">
    <img :src="store.getters.getLogo" alt="">
  </div>
</template>

<script setup>
import { onBeforeMount,ref } from 'vue';
import { useStore } from 'vuex'
import {onLang} from '@/utils/toLang'
import {info} from '@/api/api'
onLang()
const store = useStore()
let loading=ref(false)
onBeforeMount(()=>{
  loading.value=true
  setTimeout(() => {
  loading.value=false
  }, 1000);
})
info().then(res=>{
  store.dispatch('updateLogo',res.data.logo)
})
</script>

<style lang="scss">
@import '@/assets/css/common.scss';
.app-loading{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: $gray3;
  img{
    width: 80%;
  }
}
</style>
