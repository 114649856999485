import { createStore } from "vuex";

export default createStore({
  state: {
    logo:''
  },
  getters: {
    getLogo(state) {
      return state.logo;
    }
  },
  mutations: {
    updateLogo(state, payload) {
      state.logo = payload;
    },
  },
  actions: {
    updateLogo(context, payload) {
      context.commit("updateLogo", payload);
    },
  },
  modules: {},
});
