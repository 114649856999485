<template>
    <div class="payDonate" v-show="show" @click.self="show = false" @touchmove.prevent>
        <div class="pay-wrap">
            <p>
                <span class="gray">{{ $t('fenxiang_wz') }}</span>
                <span class="md1-l"><img src="@/assets/image/icon/ic_share_dropdown_down_grey.408d4252dba46353de8f.svg"
                        alt=""></span>
            </p>
            <div class="m-flex pd2-y pay-head">
                <img class="logo m-left" :src="store.getters.getLogo" alt="">
                <img class="back" @click="bank_active = null" v-show="bank_active != null"
                    src="@/assets/image/icon/ic_arrow_left_v2.9e5913cddaaa7af05e80.svg" alt="">
                <img @click="show = false" src="@/assets/image/icon/ic_close.6bdeed8ab73c2f269c4d.svg" alt="">
            </div>
           
            <p class="p32 md2-t" v-show="bank_active == null">
                {{ `${$t('xuanze')}${$t('dfk')}` }}</p>
            <ul class="bank-list" v-show="bank_active == null">
                <li class="m-flex" @click="bank_active = index" v-for="(item, index) in bank_list" :key="index">
                    <p class="m-f3 m-flex pd1-x h16"><span class="m-left">{{ item.title }}</span>
                        <img src="@/assets/image/icon/ic_arrow_right_v2.5e684bbceec789b9a719.svg" alt="">
                    </p>
                </li>
            </ul>
            <p class="p32 md2-t" v-show="bank_active != null">{{ $t('juank_srje') }}</p>
            <div class="pay-info" v-show=" bank_active != null">
                <div class="m-flex m-f-wrap pay-input">
                    <input type="text" v-model="money" placeholder="0.0" readonly >
                    <!-- <div class="pay-btn-wrap m-flex">
                        <div class="pay-btn" @click="money = item" v-for="item in pay_list" :key="item">
                            {{ item }}
                        </div>
                    </div> -->
                </div>
                <div class="bank-info" v-if="bank_active != null">
                    <div class="pay-input">
                        <input type="text" v-model="user_name" :placeholder="$t('mingcheng')">
                    </div>
                    <div class="m-flex num-input">
                        <div class="btn-white2" @click="numChange(0)">-</div>
                        <input type="number" v-model="item_num">
                        <div class="btn-white2" @click="numChange(1)">+</div>
                    </div>

                    <div class="bank-text">
                        <p class="m-flex m-f-between"><span>{{ $t('yinh_m') }}:</span><span>{{
                            bank_list[bank_active].bank_name }}</span></p>
                        <p class="m-flex m-f-between"><span>{{ $t('yinhang_dh') }}:</span><span>{{
                            bank_list[bank_active].bank_name }}</span></p>
                        <p class="m-flex m-f-between"><span>{{ $t('yinhang_zh') }}:</span><span>{{
                            bank_list[bank_active].bank_account }}</span></p>
                        <p class="m-flex m-f-between"><span>{{ $t('yinhang_yh') }}:</span><span>{{
                            bank_list[bank_active].bank_user }}</span></p>
                    </div>
                    <van-uploader v-model="fileList" :after-read="afterRead" :max-count="1" />

                </div>
            </div>

            <div class="donate-pay" @click="paySub" v-if="bank_active != null">
                {{ $t('quer_fk') }}
            </div>
            <div class="donate-footer" v-show="class_active == null || bank_active == null">
                <p class="md1-y">{{ $t('jijin_zz') }}</p>
                <img src="@/assets/image/icon/footer-logo-president-white.svg" alt="">
                <img src="@/assets/image/icon/footer-logo-kmu.svg" alt="">
                <img src="@/assets/image/icon/footer-logo-mdtu.svg" alt="">
            </div>
        </div>

    </div>
    <div class="share m-flex m-f-top" v-show="connect_show">
        <div class="m-f1">
            <input type="text" :placeholder="$t('zheng_s')">
            <div class="btn-white2" @click="connect_show = false">
                {{ $t('queding') }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, watch, defineEmits, defineModel, computed } from "vue";
import { useI18n } from "vue-i18n";

import { useStore } from 'vuex'
import { showToast } from "vant";
import { itemClassDetail, bank, upfile, pay } from '@/api/api'
const { t, locale } = useI18n()

const store = useStore()

const props = defineProps({
    item:Object
})
const show = defineModel('show')

let connect_show = ref(false)

let bank_list = ref([]),
    bank_active = ref(null)
bank().then(res => {
    bank_list.value = res.data
})
let user_name = ref(''),
    image = ref(''),
    item_num = ref(1)
let fileList = ref([])
const money=computed(()=>props.item?props.item.price * item_num.value:0)
watch(show, (New, Old) => {
    if (New != true){
        bank_active.value = null
        item_num.value=1
        image.value=''
        user_name.value=''
        fileList.value=[]
    }
})

const afterRead = (file) => {
    upfile({ image: file.file }).then(res => {
        if (res.data) {
            image.value = res.data
        } else {
            showToast(res.info)
        }
    })
};
const paySub = () => {
    if (!money.value) { return showToast(t('juank_srje')) }
    if (!user_name.value) { return showToast(t('shuru_jkr')) }
    if (!image.value) { return showToast(t('jiet_sc')) }
    pay({
        user_name: user_name.value,
        pic: image.value,
        id: bank_list.value[bank_active.value].id,
        item_id:props.item.id,
        num: item_num.value
    }).then(res => {
        showToast(res.info)
        show.value = false
        if (money.value >= 100 && res.code == 1) {
            connect_show.value = true
        }
    })
}
const numChange = (type) => {
    if (type) {
        item_num.value += 1
    } else {
        if (item_num.value > 0) {
            item_num.value -= 1
        }
    }

}
/* watch(show, (New, Old) => {
    if (New == true) {
        stopMove()
    } else {
        Move()
    }
}) */
//停止页面滚动
const stopMove = () => {
    let m = function (e) { e.preventDefault(); };
    document.body.style.overflowY = 'hidden';
    document.body.style.height = '100vh';
    document.addEventListener("touchmove", m, { passive: false });//禁止页面滑动
},
    //开启页面滚动
    Move = () => {
        let m = function (e) { e.preventDefault(); };
        document.body.style.overflow = '';//出现滚动条
        document.body.style.height = 'auto';
        document.removeEventListener("touchmove", m, { passive: true });
    }

</script>

<style lang="scss" scoped>
.num-input {
    input {
        text-align: center;
    }
}

.share {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    padding: 2em;
    z-index: 9999;
    background-color: #000000;
    border: 1px solid $gray2;

    div {
        border-left: 1px solid $gray2;

        input {
            width: 100%;
            margin-bottom: 2em;
            padding: .5em 1em;
            height: 3em;
            background-color: #000000;
            border: 1px solid $gray;

            &:hover {
                &::placeholder {
                    color: #ffffff;
                }
            }
        }
    }
}

.donate-pay {
    position: absolute;
    left: 0;
    right: $md2;
    bottom: 0;
    z-index: 9;
    background-color: $gray2;
    padding: $pd2;
    text-align: center;
    color: $gray;
    cursor: pointer;
}

.bank-text {
    &>p {
        margin: $md1 0;
        color: $gray;
    }
}

.pay-info {
    margin-right: $md2*4;
    padding-bottom: $md2*4;

    .pay-btn-wrap {
        width: 100%;
    }

    .pay-btn {
        width: 33%;
        padding: $pd2;
        background-color: $white;
        color: $gray3;
        text-align: center;
        margin-left: 1px;

        &:first-child {
            margin-left: 0;
        }
    }
}

.pay-input {
    margin: $md2 0;
    background-color: $gray2;

    input {
        height: 4em !important;
        padding: $pd2;
        flex: 1;
        height: 100%;
    }
}

.back {
    filter: invert(1);
}

.donate-footer {
    position: absolute;
    bottom: $md2;
    opacity: .5;

    img {
        width: 20%;
        margin-right: 1em;
    }
}

.pay-class,
.bank-list {
    margin: $md2;
    margin-left: 0;
    margin-right: $md2*4;

    li {
        color: $gray3;
        margin: 1px 0;

        p {
            padding: $pd2;
            background-color: #ffffff;
        }
    }
}

.pay-class1 li>p:hover,
.pay-class2 li:hover {
    &>p {
        color: $white;

        img {
            filter: invert(1);
        }
    }
}

.pay-class2 {
    width: 100%;
    margin: 0;
}

.pay-class li {
    flex-wrap: wrap;

    ul {
        flex-shrink: 0;
    }
}

.bank-list li {
    &:hover {
        background-color: #d9d9d9;
    }
}

.logo {
    width: auto;
    height: 3em;
}

.pay-wrap {
    position: relative;
    background-color: $gray3;
    width: 40%;
    height: 100vh;
    overflow-y: scroll;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;

    /* IE10+ */
    &::-webkit-scrollbar {
        display: none;
        /* ChromeSafari */
    }
}

.payDonate {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
}

@media (max-width: 650px) {
    .pay-wrap {
        width: 100%;

        .logo {
            width: 50%;
        }

        .pay-head {
            padding: $pd1;
        }

        .pay-info,
        .pay-class,
        .bank-list {
            margin-right: $md1*4;
        }

    }

    .share {
        width: 80%;
    }
}
</style>