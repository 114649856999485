import axios from "axios";
import { showLoadingToast, closeToast } from "vant";
import router from "@/router";
import { i18n } from "@/locale/index";
const { t } = i18n.global;
import { langDefault } from "@/locale/index";

const baseURL = "https://z-ero-agency.net/api"; //正式
const imgUrl="https://z-ero-agency.net/"

// 创建一个 axios 实例
const service = axios.create({
  baseURL: baseURL, // 所有的请求地址前缀部分
  // baseURL: '/api', // 所有的请求地址前缀部分
  timeout: 100000, // 请求超时时间毫秒
});

// 登录拦截白名单
// const while_router = ["/","/login", "/register", "/home", "/service", "/statement"];
// 添加请求拦截器
// service.interceptors.request.use(
//   (config) => {
//     let user_id = localStorage.getItem("id");
//     if (!user_id||typeof (user_id*1)!='number') {
//       const currentRoute = router.currentRoute.value.path,
//         is_login = while_router.indexOf(currentRoute);
//       if (is_login == -1) {
//         localStorage.removeItem("id");
//         router.push("login");
//       }
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// 添加响应拦截器
service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export { service, baseURL,imgUrl };
