export const onLang = () => {
  let cn = require("@/locale/lang/hk.js"),
    my_lan = require("./lang.js");
  let str = setTreat(cn);
  console.log("未翻译", str);
  let lang_obj = onTreat(cn, my_lan.split("\n")).lan_obj;
  console.log("翻译过后", lang_obj);
};

export const onTreat = function (lan, lans, index = 0) {
  let lan_obj = {};
  for (let key in lan) {
    let val = lan[key];
    if (typeof val == "string") {
      lan_obj[key] = lans[index];
      console.log(key, val, lan_obj[key]);
    } else if (val instanceof Array) {
      lan_obj[key] = [];
      lan_obj[key] = onTreat(val, lans, index).lan_obj;
      index = onTreat(val, lans, index).index;
    } else if (val instanceof Object) {
      lan_obj[key] = {};
      lan_obj[key] = onTreat(val, lans, index).lan_obj;
      index = onTreat(val, lans, index).index;
    }
    index += 1;
  }
  index -= 1;
  return {
    lan_obj,
    index,
  };
};

export const setTreat = function (cn) {
  let lang_str = "";
  treat(cn);

  function treat(lan) {
    let lan_obj = {},
      index = 0;
    for (let key in lan) {
      let val = lan[key];
      if (typeof val == "string") {
        lan_obj[index] = val;
        lang_str += `${val}\n`;
      } else if (val instanceof Array) {
        lan_obj[index] = [];
        lan_obj[index] = treat(val);
      } else if (val instanceof Object) {
        lan_obj[index] = {};
        lan_obj[index] = treat(val);
      }
      index += 1;
    }
    return lan_obj;
  }
  onRepeat(cn, lang_str.split("\n"));
  return lang_str;
};

function onRepeat(lan, lans, index = 0) {
  let lan_obj = {};
  for (let key in lan) {
    let val = lan[key];
    if (typeof val == "string") {
      if (val != lans[index]) {
        alert(`${key}出错`, index);
        return;
      }
    } else if (val instanceof Array) {
      lan_obj[key] = [];
      lan_obj[key] = onRepeat(val, lans, index).lan_obj;
      index = onRepeat(val, lans, index).index;
    } else if (val instanceof Object) {
      lan_obj[key] = {};
      lan_obj[key] = onRepeat(val, lans, index).lan_obj;
      index = onRepeat(val, lans, index).index;
    }
    index += 1;
  }
  index -= 1;
  return {
    lan_obj,
    index,
  };
}

const exportWord = (domOrSelector, fileName = "word", style = "") => {
  if (!domOrSelector) {
      return Promise.reject("缺少导出元素或者导出元素选择器");
  }

  const dom =
      typeof domOrSelector === "string"
      ? document.querySelector(domOrSelector)
      : domOrSelector;

  if (!dom) {
      return Promise.reject("未获取到元素选择器对应的元素");
  }

  const exportHtml = `
      <html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:w="urn:schemas-microsoft-com:office:word" xmlns="http://www.w3.org/TR/REC-html40">
          <head>
              <meta charset='utf-8'>
              <title>${fileName}</title>
              ${style ? `<style>${style}</style>` : ""}
          </head>
          <body>
              ${dom.outerHTML}
          </body>
      </html>
  `;

  const url =
      "data:application/vnd.ms-word;charset=utf-8," +
      encodeURIComponent(exportHtml);

  download(url, fileName + ".doc");

  return Promise.resolve(true);
}
