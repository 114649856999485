import { createI18n } from 'vue-i18n' //引入vue-i18n组件

import en from './lang/en.js'
import RU from './lang/RU.js'
import hk from './lang/hk.js'
import es from './lang/es.js'
import pt from './lang/pt.js'
import de from './lang/de.js'
import it from './lang/it.js'
import ar from './lang/ar.js'

export const messages={
    en,RU,hk,es,pt,it,ar,de
}
export const lang_list={
    'en': 'English',
	'RU': 'українська',
    'hk':'繁體中文',
    'es':'español',
    'pt':'Português',
    'it':'Italiano',
    ar:'عربي',
    de:'Deutsch'

}

export const langDefault=()=>{
    lang='RU'
    localStorage.setItem('lang',lang)
}
let lang = localStorage.getItem('lang')
if(!lang||!messages[lang]){
    langDefault()
}

export const i18n = createI18n({
    globalInjection:true,
    legacy: false,
    locale: lang,
    messages
  })