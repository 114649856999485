import { service,baseURL,imgUrl} from "@/api/request";
import qs from "qs";

const http = service;
export const img_url=imgUrl


// 网站配置
export const info = (params) => {
  return http.post("/index/info", qs.stringify(params)).then((result) => {
    return result.data;
  });
}

// 项目列表
export const indexItem = (params) => {
  return http.post("/index/item", qs.stringify(params)).then((result) => {
    return result.data;
  });
}
// 项目列表
export const itemDetail = (params) => {
  return http.post("/index/itemDetail", qs.stringify(params)).then((result) => {
    return result.data;
  });
}
// 方向列表
export const itemClassDetail = (params) => {
  return http.post("/index/itemClassDetail", qs.stringify(params)).then((result) => {
    return result.data;
  });
}
// 大使列表
export const envoy = (params) => {
  return http.post("/index/envoy", qs.stringify(params)).then((result) => {
    return result.data;
  });
}
// 大使详情
export const envoyDetail = (params) => {
  return http.post("/index/envoyDetail", qs.stringify(params)).then((result) => {
    return result.data;
  });
}

// 文章
export const article = (params) => {
  return http.post("/index/article", qs.stringify(params)).then((result) => {
    return result.data;
  });
}

// 支付列表
export const bank = (params) => {
  return http.post("/index/bank", qs.stringify(params)).then((result) => {
    return result.data;
  });
}

// 捐助者
export const donors = (params) => {
  return http.post("/index/donors", qs.stringify(params)).then((result) => {
    return result.data;
  });
}

// 新闻详情
export const newsDetail = (params) => {
  return http.post("/index/newsDetail", qs.stringify(params)).then((result) => {
    return result.data;
  });
}
// 新闻
export const news = (params) => {
  return http.post("/index/news", qs.stringify(params)).then((result) => {
    return result.data;
  });
}
// 支付
export const pay = (params) => {
  return http.post("/index/pay", qs.stringify(params)).then((result) => {
    return result.data;
  });
}
// 支付
export const donors2 = (params) => {
  return http.post("/index/donors2", qs.stringify(params)).then((result) => {
    return result.data;
  });
}



// 上传图片
export const upfile = (params) => {
  const formData = new FormData();
  for(let key in params){
    formData.append(key,params[key]);
  }
  return http
    .post("/index/upload", formData, {
      headers: {
        // 表示上传的是文件,而不是普通的表单数据
        "Content-Type": "multipart/form-data",
      },
    })
    .then((result) => {
      return result.data;
    });
};
