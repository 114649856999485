import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import 'vant/lib/index.css';

import {i18n} from '@/locale/index'
import { Form, Field, CellGroup,Button,Checkbox, CheckboxGroup,Popup,Icon,Cell,Uploader,CountDown,Swipe, SwipeItem,Toast   } from 'vant';

import 'video.js/dist/video-js.css'
import pageDonate from '@/components/pageDonate.vue'


createApp(App).use(store).use(router).use(i18n).
use(Form).use(Field).use(CellGroup).use(Button).use(Checkbox).use(CheckboxGroup).use(Popup).use(Icon).use(Cell).use(Uploader).use(CountDown).use(Swipe).use(SwipeItem).use(Toast)
.component('pageDonate',pageDonate)
.mount('#app')
