module.exports={
    "$": "$",
    "head_t1": "Offizielle Website der ukrainischen Regierung",
    "gengduo_yd": "Mehr lesen",
    "fenxiang_wz": "Website teilen",
    "zong_sj": "Gesamtsammelbetrag",
    "xiangmu": "Projekt",
    "dashi": "Botschafter",
    "liji_jz": "Jetzt spenden",
    "shipin_cw": "Dieses Video kann nicht abgespielt werden. Bitte versuchen Sie es später erneut",
    "banner_t1": "Initiative des Präsidenten der Ukraine",
    "banner_t2": "Die offizielle Fundraising-Plattform der Ukraine",
    "guank_sp": "Video ansehen",
    "zhichi_wm": "unsere Unterstützer",
    "zuixin_xm": "Neueste Projekte",
    "juanz_lx": {
        "0": "Verteidigung",
        "1": "Bildung und Wissenschaft",
        "2": "humanitär",
        "3": "medizinische Hilfe"
    },
    "suoyou_xm": "Alle Artikel anzeigen",
    "report_t1": "Transparenz und Effizienz",
    "report_t2": "ZEROAGENCY stellt jede Woche Fondszuteilungsberichte in fünf Richtungen zur Verfügung",
    "report_t3": "Menschen aus aller Welt sind vereint und bereit, der Ukraine zu helfen. Ziel von ZEROAGENCY ist es, die Spenden an die Ukraine zu steigern und Effizienz und Transparenz bei deren Verteilung sicherzustellen.",
    "guanyu_wz": "ÜBER ZEROAGency",
    "shouji_zs": "Gesamtsammlung",
    "juanz_zj": "Spenden Sie direkt",
    "baogao_qw": "Vollständiger Text des Berichts",
    "dashi_wm": "unser Botschafter",
    "suoyou_dsxs": "Alle Botschafter anzeigen",
    "fabu_sj": "In den sozialen Medien posten",
    "fenx": "Aktie",
    "dingy_t1": "Abonnieren Sie den wöchentlichen Newsletter",
    "dingy_t2": "Abonnieren Sie ZEROAGENCY-Berichte, Neuigkeiten und Updates",
    "fenx_p": {
        "0": "Facebook",
        "1": "Ölrohr",
        "2": "Telegraph"
    },
    "dingye": "Abonnement",
    "shuru_yx": "Geben Sie Ihre E-Mail-Adresse ein",
    "zuixin_xx": "neueste Nachrichten",
    "suoyou_xwxs": "Alle Neuigkeiten anzeigen",
    "zhichi_t1": "Unterstützen Sie die Ukraine in dieser kritischen Zeit",
    "juank_lj": "Jetzt spenden",
    "nian": "Jahr",
    "yinsi_sm": "Datenschutzerklärung",
    "lianxi_wm": "Kontaktieren Sie uns",
    "lianxi_t1": "Wenn Sie weitere Fragen oder Bedenken haben",
    "xinwen": "Nachricht",
    "juanzhu_z": "Spender",
    "guanyu_wm": "über uns",
    "yiban_wt": "Allgemeine Fragen",
    "duiyu_mt": "für Medien",
    "duiyu_hz": "Partner",
    "sy_xm": "Alle Artikel",
    "xiangm_yw_xs": "Abgeschlossene Projekte anzeigen",
    "project_t1": "Wir suchen Partner in produzierenden Unternehmen, die bereit sind, uns bei der Erfüllung unserer spezifischen Bedürfnisse zu unterstützen",
    "project_t2": "Um Partner zu werden, schreiben Sie an",
    "project_t3": "partnerschaft@u24.gov.ua",
    "project_t4": "Wir suchen Partner in produzierenden Unternehmen, die bereit sind, uns bei der Erfüllung unserer spezifischen Bedürfnisse zu unterstützen.",
    "project_t5": "Ziel von ZEROAGENCY ist es, die Spenden an die Ukraine zu steigern und Effizienz und Transparenz bei deren Verteilung sicherzustellen. Die offizielle Plattform schützt Sie vor Verzögerungen, Zwischenhändlern und Betrug.",
    "envoy_t1": "Präsident Selenskyj hat Meinungsführer aus der ganzen Welt versammelt, um der Ukraine zu helfen.",
    "envoy_t2": "Schauspieler und Regisseure, Musiker, Wissenschaftler und Sportler bilden zusammen das ZEROAGENCY-Botschafterteam. An diesem Wendepunkt in der Geschichte brachte sie der Wille, der Ukraine zu helfen, zusammen. Sie helfen der Plattform dabei, Gelder für die Ukraine zu sammeln und sie ins internationale Rampenlicht zu rücken, wobei sie als ständige Erinnerung an ihren Kampf für Freiheit und die Werte einer demokratischen Welt dienen. Ihr Beitrag ist unbezahlbar.",
    "suoyou_ds": "alle Botschafter",
    "jiazai_gd": "mehr laden",
    "record_input": "Suchen Sie nach Name oder Stichwort",
    "suoyou_gj": "Alle Länder/Regionen",
    "juanzheng_zgs": "Spendergeschichten",
    "ditu": "Karte",
    "ka": "Karte",
    "suoyou_xw": "Alle Neuigkeiten",
    "meiti_bd": "Medienberichte",
    "ruhe_gz": "wie er arbeitet",
    "zijin_zz": "Finanzierung",
    "xianshi_qb": "Alle anzeigen",
    "fangx": "Richtung",
    "xuanze_nyj": "Wählen Sie aus, wo Sie spenden möchten",
    "jijin_zz": "Finanzierung",
    "xuanze": "wählen",
    "dfk": "Zahlungsart",
    "juank_srje": "Geben Sie den Spendenbetrag ein",
    "mingcheng": "Name",
    "yinhang_dh": "Telefonnummer der Bank",
    "yinh_m": "Name der Bank",
    "yinhang_zh": "Bankkonto",
    "yinhang_yh": "Bankbenutzer",
    "quer_fk": "Bestätigen Sie die Zahlung",
    "shuru_jkr": "Geben Sie den Namen des Spenders ein",
    "jiet_sc": "Spenden-Screenshot hochladen",
    "shif_fn": "Wut loslassen",
    "juan_k": "Spenden",
    "juan_zjl": "Spendenaufzeichnungen",
    "queding": "Sicher",
    "zheng_s": "Hinterlassen Sie Ihre E-Mail-Adresse und Kontaktadresse, damit wir Ihnen eine Liebesurkunde und eine Trophäe zusenden können",
    "Please enter your email address": "Bitte geben Sie Ihre E-Mail- Adresse ein",
    "You have subscribed to the latest news": "Sie haben die neuesten Nachrichten abonniert"
}