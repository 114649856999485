module.exports={
    "$": "$",
    "head_t1": "الموقع الرسمي للحكومة الأوكرانية",
    "gengduo_yd": "اقرأ المزيد",
    "fenxiang_wz": "مشاركة الموقع",
    "zong_sj": "المبلغ الإجمالي للتحصيل",
    "xiangmu": "مشروع",
    "dashi": "سفير",
    "liji_jz": "تبرع الآن",
    "shipin_cw": "لا يمكن تشغيل هذا الفيديو، يرجى المحاولة مرة أخرى لاحقًا",
    "banner_t1": "مبادرة رئيس أوكرانيا",
    "banner_t2": "المنصة الرسمية لجمع التبرعات في أوكرانيا",
    "guank_sp": "شاهد الفيديو",
    "zhichi_wm": "أنصارنا",
    "zuixin_xm": "أحدث المشاريع",
    "juanz_lx": {
        "0": "دفاع",
        "1": "التعليم والعلوم",
        "2": "إنسانية",
        "3": "المساعدة الطبية"
    },
    "suoyou_xm": "إظهار كافة العناصر",
    "report_t1": "الشفافية والكفاءة",
    "report_t2": "توفر ZEROAGENCY تقارير تخصيص الأموال في خمسة اتجاهات كل أسبوع",
    "report_t3": "الناس من جميع أنحاء العالم متحدون ومستعدون لمساعدة أوكرانيا. تهدف ZEROAGENCY إلى زيادة التبرعات لأوكرانيا وضمان الكفاءة والشفافية في توزيعها.",
    "guanyu_wz": "حول الصفر",
    "shouji_zs": "مجموع المجموعة",
    "juanz_zj": "التبرع مباشرة",
    "baogao_qw": "النص الكامل للتقرير",
    "dashi_wm": "سفيرنا",
    "suoyou_dsxs": "عرض جميع السفراء",
    "fabu_sj": "انشر على وسائل التواصل الاجتماعي",
    "fenx": "يشارك",
    "dingy_t1": "اشترك في النشرة الأسبوعية",
    "dingy_t2": "اشترك في تقارير ZEROAGENCY والأخبار والتحديثات",
    "fenx_p": {
        "0": "فيسبوك",
        "1": "أنبوب النفط",
        "2": "تلغراف"
    },
    "dingye": "الاشتراك",
    "shuru_yx": "أدخل بريدك الإلكتروني",
    "zuixin_xx": "آخر الأخبار",
    "suoyou_xwxs": "عرض كل الأخبار",
    "zhichi_t1": "دعم أوكرانيا في هذا الوقت الحرج",
    "juank_lj": "تبرع الآن",
    "nian": "سنة",
    "yinsi_sm": "بيان الخصوصية",
    "lianxi_wm": "اتصل بنا",
    "lianxi_t1": "إذا كان لديك أي أسئلة أو مخاوف أخرى",
    "xinwen": "أخبار",
    "juanzhu_z": "الجهة المانحة",
    "guanyu_wm": "معلومات عنا",
    "yiban_wt": "أسئلة عامة",
    "duiyu_mt": "لوسائل الإعلام",
    "duiyu_hz": "الشركاء",
    "sy_xm": "جميع العناصر",
    "xiangm_yw_xs": "عرض المشاريع المكتملة",
    "project_t1": "نحن نبحث عن شركاء في شركات التصنيع الذين هم على استعداد لمساعدتنا في تلبية احتياجاتنا المحددة",
    "project_t2": "لتصبح شريكا الكتابة ل",
    "project_t3": "Partnership@u24.gov.ua",
    "project_t4": "نحن نبحث عن شركاء في شركات التصنيع الذين هم على استعداد لمساعدتنا في تلبية احتياجاتنا المحددة.",
    "project_t5": "تهدف ZEROAGENCY إلى زيادة التبرعات لأوكرانيا وضمان الكفاءة والشفافية في توزيعها. المنصة الرسمية تحميك من التأخير والوسطاء والاحتيال.",
    "envoy_t1": "وقد حشد الرئيس زيلينسكي قادة الرأي من جميع أنحاء العالم لمساعدة أوكرانيا.",
    "envoy_t2": "يجتمع الممثلون والمخرجون والموسيقيون والعلماء والرياضيون معًا لتشكيل فريق سفراء ZEROAGENCY. وعند نقطة التحول هذه في التاريخ، جمعتهما الرغبة في مساعدة أوكرانيا. إنهم يساعدون المنصة في جمع الأموال لأوكرانيا ووضعها في دائرة الضوء الدولية، ليكونوا بمثابة تذكير دائم بكفاحها من أجل الحرية وقيم العالم الديمقراطي. مدخلاتهم لا تقدر بثمن.",
    "suoyou_ds": "جميع السفراء",
    "jiazai_gd": "تحميل المزيد",
    "record_input": "البحث بالاسم أو الكلمة الرئيسية",
    "suoyou_gj": "جميع البلدان/المناطق",
    "juanzheng_zgs": "قصص المانحين",
    "ditu": "رسم خريطة",
    "ka": "بطاقة",
    "suoyou_xw": "كل الأخبار",
    "meiti_bd": "تقارير وسائل الإعلام",
    "ruhe_gz": "كيف يعمل",
    "zijin_zz": "التمويل",
    "xianshi_qb": "عرض الكل",
    "fangx": "اتجاه",
    "xuanze_nyj": "اختر المكان الذي تريد التبرع فيه",
    "jijin_zz": "التمويل",
    "xuanze": "يختار",
    "dfk": "طريقة الدفع",
    "juank_srje": "أدخل مبلغ التبرع",
    "mingcheng": "اسم",
    "yinhang_dh": "رقم هاتف البنك",
    "yinh_m": "اسم البنك",
    "yinhang_zh": "حساب مصرفي",
    "yinhang_yh": "مستخدم البنك",
    "quer_fk": "تأكيد الدفع",
    "shuru_jkr": "أدخل اسم الجهة المانحة",
    "jiet_sc": "تحميل لقطة شاشة التبرع",
    "shif_fn": "الافراج عن الغضب",
    "juan_k": "يتبرع",
    "juan_zjl": "سجلات التبرع",
    "queding": "بالتأكيد",
    "zheng_s": "اترك بريدك الإلكتروني وعنوان الاتصال الخاص بك حتى نتمكن من إرسال شهادة حب وكأس إليك",
    "Please enter your email address": "عنوان بريدك الإلكتروني",
    "You have subscribed to the latest news": "لقد اشتركت في آخر الأخبار"
}