module.exports={
    "$": "$",
    "head_t1": "Sito ufficiale del governo ucraino",
    "gengduo_yd": "Per saperne di più",
    "fenxiang_wz": "condividere il sito web",
    "zong_sj": "Importo totale della raccolta",
    "xiangmu": "progetto",
    "dashi": "ambasciatore",
    "liji_jz": "Dona ora",
    "shipin_cw": "Impossibile riprodurre questo video, riprova più tardi",
    "banner_t1": "Iniziativa del presidente dell'Ucraina",
    "banner_t2": "La piattaforma ufficiale di raccolta fondi dell’Ucraina",
    "guank_sp": "guarda il video",
    "zhichi_wm": "i nostri sostenitori",
    "zuixin_xm": "Ultimi progetti",
    "juanz_lx": {
        "0": "difesa",
        "1": "Educazione e scienza",
        "2": "umanitario",
        "3": "assistenza medica"
    },
    "suoyou_xm": "Mostra tutti gli articoli",
    "report_t1": "Trasparenza ed efficienza",
    "report_t2": "ZEROAGENCY fornisce ogni settimana resoconti sull'allocazione dei fondi in cinque direzioni",
    "report_t3": "Persone provenienti da tutto il mondo sono unite e disposte ad aiutare l’Ucraina. ZEROAGENCY mira ad aumentare le donazioni all'Ucraina e garantire efficienza e trasparenza nella loro distribuzione.",
    "guanyu_wz": "SU ZEROAGENCY",
    "shouji_zs": "Collezione totale",
    "juanz_zj": "Dona direttamente",
    "baogao_qw": "Testo completo del rapporto",
    "dashi_wm": "il nostro ambasciatore",
    "suoyou_dsxs": "Mostra tutti gli ambasciatori",
    "fabu_sj": "Pubblica sui social media",
    "fenx": "condividere",
    "dingy_t1": "Iscriviti alla newsletter settimanale",
    "dingy_t2": "Iscriviti ai rapporti, alle notizie e agli aggiornamenti di ZEROAGENCY",
    "fenx_p": {
        "0": "Facebook",
        "1": "tubo dell'olio",
        "2": "telegrafo"
    },
    "dingye": "sottoscrizione",
    "shuru_yx": "Inserisci la tua email",
    "zuixin_xx": "ultime notizie",
    "suoyou_xwxs": "Mostra tutte le novità",
    "zhichi_t1": "Sostieni l’Ucraina in questo momento critico",
    "juank_lj": "Dona ora",
    "nian": "Anno",
    "yinsi_sm": "Informativa sulla privacy",
    "lianxi_wm": "Contattaci",
    "lianxi_t1": "Se hai altre domande o dubbi",
    "xinwen": "notizia",
    "juanzhu_z": "Donatore",
    "guanyu_wm": "chi siamo",
    "yiban_wt": "Domande generali",
    "duiyu_mt": "per i media",
    "duiyu_hz": "Partner",
    "sy_xm": "Tutti gli articoli",
    "xiangm_yw_xs": "Mostra i progetti completati",
    "project_t1": "Cerchiamo partner in aziende manifatturiere che siano disposti ad aiutarci a soddisfare le nostre specifiche esigenze",
    "project_t2": "Per diventare partner scrivi a",
    "project_t3": "partnership@u24.gov.ua",
    "project_t4": "Cerchiamo partner in aziende manifatturiere che siano disposti ad aiutarci a soddisfare le nostre specifiche esigenze.",
    "project_t5": "ZEROAGENCY mira ad aumentare le donazioni all'Ucraina e garantire efficienza e trasparenza nella loro distribuzione. La piattaforma ufficiale ti protegge da ritardi, intermediari e frodi.",
    "envoy_t1": "Il presidente Zelenskyj ha radunato gli opinion leader di tutto il mondo per aiutare l’Ucraina.",
    "envoy_t2": "Attori e registi, musicisti, scienziati e atleti si uniscono per formare il team di ambasciatori di ZEROAGENCY. In questo momento di svolta nella storia, la volontà di aiutare l’Ucraina li ha uniti. Aiutano la piattaforma a raccogliere fondi per l’Ucraina e a metterla sotto i riflettori internazionali, ricordando costantemente la sua lotta per la libertà e i valori di un mondo democratico. Il loro contributo non ha prezzo.",
    "suoyou_ds": "tutti gli ambasciatori",
    "jiazai_gd": "caricare di più",
    "record_input": "Cerca per nome o parola chiave",
    "suoyou_gj": "Tutti i paesi/regioni",
    "juanzheng_zgs": "Storie di donatori",
    "ditu": "mappa",
    "ka": "Carta",
    "suoyou_xw": "Tutte le novità",
    "meiti_bd": "resoconti dei media",
    "ruhe_gz": "come lavora",
    "zijin_zz": "Finanziamento",
    "xianshi_qb": "Mostra tutto",
    "fangx": "direzione",
    "xuanze_nyj": "Scegli dove vuoi donare",
    "jijin_zz": "Finanziamento",
    "xuanze": "scegliere",
    "dfk": "metodo di pagamento",
    "juank_srje": "Inserisci l'importo della donazione",
    "mingcheng": "nome",
    "yinhang_dh": "Numero di telefono della banca",
    "yinh_m": "Nome della banca",
    "yinhang_zh": "conto bancario",
    "yinhang_yh": "Utente della banca",
    "quer_fk": "Conferma il pagamento",
    "shuru_jkr": "Inserisci il nome del donatore",
    "jiet_sc": "Carica lo screenshot della donazione",
    "shif_fn": "rilasciare la rabbia",
    "juan_k": "Donare",
    "juan_zjl": "Registri delle donazioni",
    "queding": "Sicuro",
    "zheng_s": "Lascia la tua email e l'indirizzo di contatto così possiamo inviarti un certificato d'amore e un trofeo",
    "Please enter your email address": "Inserisci il tuo indirizzo email",
    "You have subscribed to the latest news": "Ti sei iscritto alle ultime notizie"
}