<template>
    <div class="m-flex p16 page-donate">
            <div class="blue-btn m-f1">
                {{ $t('zhichi_t1') }}
            </div>
            <div class="yellow-btn m-f1" @click="router.push('/project')">
                {{ $t('juank_lj') }}
            </div>
        </div>
        <payDonate v-model:show="donate_show"></payDonate>
</template>

<script setup>
import payDonate from '@/components/payDonate'
import { useRouter } from 'vue-router'
const router = useRouter()
import { ref, defineProps, defineEmits, defineModel } from "vue";
const props = defineProps({
    list: [Array, Object]
})
let donate_show=ref(false)
</script>

<style lang="scss" scoped>
</style>