module.exports={
    "$": "$",
    "head_t1": "Sitio web oficial del gobierno ucraniano",
    "gengduo_yd": "Leer más",
    "fenxiang_wz": "compartir sitio web",
    "zong_sj": "Monto total de recaudación",
    "xiangmu": "proyecto",
    "dashi": "embajador",
    "liji_jz": "Donar ahora",
    "shipin_cw": "Este vídeo no se puede reproducir, inténtalo de nuevo más tarde.",
    "banner_t1": "Iniciativa del presidente de Ucrania",
    "banner_t2": "La plataforma oficial de recaudación de fondos de Ucrania",
    "guank_sp": "ver vídeo",
    "zhichi_wm": "nuestros seguidores",
    "zuixin_xm": "Últimos proyectos",
    "juanz_lx": {
        "0": "defensa",
        "1": "Educación y ciencia",
        "2": "humanitario",
        "3": "asistencia medica"
    },
    "suoyou_xm": "Mostrar todos los artículos",
    "report_t1": "Transparencia y eficiencia",
    "report_t2": "ZEROAGENCY proporciona informes de asignación de fondos en cinco direcciones cada semana",
    "report_t3": "Personas de todo el mundo están unidas y dispuestas a ayudar a Ucrania. ZEROAGENCY tiene como objetivo aumentar las donaciones a Ucrania y garantizar la eficiencia y transparencia en su distribución.",
    "guanyu_wz": "ACERCA DE LA AGENCIA CERO",
    "shouji_zs": "recaudación total",
    "juanz_zj": "Donar directamente",
    "baogao_qw": "Texto completo del informe",
    "dashi_wm": "nuestro embajador",
    "suoyou_dsxs": "Mostrar todos los embajadores",
    "fabu_sj": "Publicar en las redes sociales",
    "fenx": "compartir",
    "dingy_t1": "Suscríbete al boletín semanal",
    "dingy_t2": "Suscríbete a informes, noticias y actualizaciones de ZEROAGENCY",
    "fenx_p": {
        "0": "Facebook",
        "1": "tubo de aceite",
        "2": "telégrafo"
    },
    "dingye": "suscripción",
    "shuru_yx": "Introduce tu correo electrónico",
    "zuixin_xx": "últimas noticias",
    "suoyou_xwxs": "Mostrar todas las noticias",
    "zhichi_t1": "Apoye a Ucrania en este momento crítico",
    "juank_lj": "Donar ahora",
    "nian": "Año",
    "yinsi_sm": "Declaración de privacidad",
    "lianxi_wm": "Contáctenos",
    "lianxi_t1": "Si tienes alguna otra pregunta o inquietud",
    "xinwen": "noticias",
    "juanzhu_z": "Donante",
    "guanyu_wm": "sobre nosotros",
    "yiban_wt": "Preguntas generales",
    "duiyu_mt": "para los medios",
    "duiyu_hz": "Fogonadura",
    "sy_xm": "Todos los artículos",
    "xiangm_yw_xs": "Mostrar proyectos completados",
    "project_t1": "Buscamos socios en empresas de fabricación que estén dispuestos a ayudarnos a satisfacer nuestras necesidades específicas.",
    "project_t2": "Para hacerse socio escriba a",
    "project_t3": "asociación@u24.gov.ua",
    "project_t4": "Buscamos socios en empresas de fabricación que estén dispuestos a ayudarnos a satisfacer nuestras necesidades específicas.",
    "project_t5": "ZEROAGENCY tiene como objetivo aumentar las donaciones a Ucrania y garantizar la eficiencia y transparencia en su distribución. La plataforma oficial te protege de retrasos, intermediarios y fraudes.",
    "envoy_t1": "El presidente Zelensky ha movilizado a líderes de opinión de todo el mundo para ayudar a Ucrania.",
    "envoy_t2": "Actores y directores, músicos, científicos y deportistas se unen para formar el equipo embajador de ZEROAGENCY. En este momento decisivo de la historia, la voluntad de ayudar a Ucrania los unió. Ayudan a la plataforma a recaudar fondos para Ucrania y a ponerla en el centro de atención internacional, sirviendo como un recordatorio constante de su lucha por la libertad y los valores de un mundo democrático. Su aportación no tiene precio.",
    "suoyou_ds": "todos los embajadores",
    "jiazai_gd": "cargar más",
    "record_input": "Buscar por nombre o palabra clave",
    "suoyou_gj": "Todos los países/regiones",
    "juanzheng_zgs": "Historias de donantes",
    "ditu": "mapa",
    "ka": "Tarjeta",
    "suoyou_xw": "Todas las noticias",
    "meiti_bd": "informes de los medios",
    "ruhe_gz": "como trabaja",
    "zijin_zz": "Fondos",
    "xianshi_qb": "Mostrar todo",
    "fangx": "dirección",
    "xuanze_nyj": "Elige dónde quieres donar",
    "jijin_zz": "Fondos",
    "xuanze": "elegir",
    "dfk": "método de pago",
    "juank_srje": "Ingrese el monto de la donación",
    "mingcheng": "nombre",
    "yinhang_dh": "Número de teléfono del banco",
    "yinh_m": "nombre del banco",
    "yinhang_zh": "cuenta bancaria",
    "yinhang_yh": "Usuario del banco",
    "quer_fk": "Confirmar pago",
    "shuru_jkr": "Introduzca el nombre del donante",
    "jiet_sc": "Subir captura de pantalla de donación",
    "shif_fn": "liberar la ira",
    "juan_k": "Donar",
    "juan_zjl": "Registros de donación",
    "queding": "Seguro",
    "zheng_s": "Deja tu correo electrónico y dirección de contacto para que podamos enviarte un certificado de amor y un trofeo.",
    "Please enter your email address": "Por favor ingrese su dirección de correo electrónico",
    "You have subscribed to the latest news": "Te has suscrito a las últimas noticias."
}