export const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/index.vue"),
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/home/home.vue"),
      },{
        path:'/project',
        name:'project',
        component: () => import("@/views/project/project.vue")
      },{
        path:'/projectDetail',
        name:'projectDetail',
        component: () => import("@/views/project/projectDetail.vue")
      },{
        path:'/envoy',
        name: "envoy",
        component: () => import("@/views/envoy/envoy.vue")
      },{
        path:'/envoyDetail',
        name: "envoyDetail",
        component: () => import("@/views/envoy/envoyDetail.vue")
      },{
        path:'/record',
        name: "record",
        component: () => import("@/views/record/record.vue")
      },{
        path:'/news',
        name: "news",
        component: () => import("@/views/news/news.vue")
      },{
        path:'/newsDetail',
        name: "newsDetail",
        component: () => import("@/views/news/newsDetail.vue")
      },{
        path:'/about',
        name: "about",
        component: () => import("@/views/about/about.vue")
      }
    ]
  }
]
